import QueryString from "query-string";

var summaryDialog;
var filingDialog;
var summaryEvent;

Office.onReady(() => {
  // If needed, Office.js is ready to be called
});

/**
 * Shows a notification when the add-in command is executed.
 * @param event {Office.AddinCommands.Event}
 */
function action(event) {
  console.log("Action function called");

  Office.context.auth.getAccessTokenAsync(function(result) {
    if (result.status === "succeeded") {
      let url = window.location.origin + "/filingMessage.html";

      Office.context.ui.displayDialogAsync(url, { height: 25, width: 25, displayInIframe: true }, asyncResult => {
        if (asyncResult.status == "failed") {
          // In addition to general system errors, there are 3 specific errors for
          // displayDialogAsync that you can handle individually.
          switch (asyncResult.error.code) {
            case 12004:
              showNotification("Domain is not trusted");
              break;
            case 12005:
              showNotification("HTTPS is required");
              break;
            case 12007:
              showNotification("A dialog is already opened.");
              break;
            default:
              showNotification(asyncResult.error.message);
              break;
          }
        } else {
          console.log("Adding handlers");
          filingDialog = asyncResult.value;
          filingDialog.addEventHandler(Office.EventType.DialogMessageReceived, filingMessageHandler);
          filingDialog.addEventHandler(Office.EventType.DialogEventReceived, filingEventHandler);
        }
      });

      // Use this token to call Web API
      var ssoToken = result.value;
      console.log("SSO token obtained");
      console.log(ssoToken);

      fetch("https://atveroattachments.azurewebsites.net/api/FileEmailInFolder", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + ssoToken
        }
      })
        .then(response => response.json())
        .then(responseData => {
          console.log("Fetch returned");
          console.log(responseData);

          filingDialog.close();
          event.completed();
        })
        .catch(err => {
          // Show a notification message
          Office.context.mailbox.item.notificationMessages.replaceAsync("action", {
            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
            message: "Performed action.",
            icon: "Icon.80x80",
            persistent: true
          });
          console.log("Failed with error " + err);
        });
    } else {
      if (result.error.code === 13003) {
        // SSO is not supported for domain user accounts, only
        // work or school (Office 365) or Microsoft Account IDs.
        console.log("SSO failed with error 13003");

        // Show a notification message
        Office.context.mailbox.item.notificationMessages.replaceAsync("action", {
          type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
          message: "Performed action.",
          icon: "Icon.80x80",
          persistent: true
        });

        event.completed();
      } else {
        // Handle error
        console.log("SSO - general failure");

        // Show a notification message
        Office.context.mailbox.item.notificationMessages.replaceAsync("action", {
          type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
          message: "Performed action.",
          icon: "Icon.80x80",
          persistent: true
        });
        console.log(result.error);
        event.completed();
      }
    }
  });

  // Be sure to indicate when the add-in command function is complete
  // event.completed();
}

function filingStatus(event) {
  console.log("Action function called");

  Office.context.auth.getAccessTokenAsync(function(result) {
    if (result.status === "succeeded") {
      // Use this token to call Web API
      var ssoToken = result.value;
      console.log("SSO token obtained");
      console.log(ssoToken);

      fetch("https://atveroattachments.azurewebsites.net/api/FileEmailInFolder?action=status", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + ssoToken
        }
      })
        .then(response => response.json())
        .then(responseData => {
          console.log("Fetch returned");
          console.log(responseData);
          summaryEvent = event;
          let summary = { summary: JSON.stringify(responseData) };

          let url = window.location.origin + "/summary.html?" + QueryString.stringify(summary);

          Office.context.ui.displayDialogAsync(url, { height: 25, width: 25, displayInIframe: true }, asyncResult => {
            if (asyncResult.status == "failed") {
              // In addition to general system errors, there are 3 specific errors for
              // displayDialogAsync that you can handle individually.
              switch (asyncResult.error.code) {
                case 12004:
                  showNotification("Domain is not trusted");
                  break;
                case 12005:
                  showNotification("HTTPS is required");
                  break;
                case 12007:
                  showNotification("A dialog is already opened.");
                  break;
                default:
                  showNotification(asyncResult.error.message);
                  break;
              }
            } else {
              console.log("Adding handlers");
              summaryDialog = asyncResult.value;
              summaryDialog.addEventHandler(Office.EventType.DialogMessageReceived, summaryMessageHandler);
              summaryDialog.addEventHandler(Office.EventType.DialogEventReceived, summaryEventHandler);
            }
          });
        })
        .catch(err => {
          // Show a notification message
          Office.context.mailbox.item.notificationMessages.replaceAsync("action", {
            type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
            message: "Performed action.",
            icon: "Icon.80x80",
            persistent: true
          });
          console.log("Failed with error " + err);
        });
    } else {
      if (result.error.code === 13003) {
        // SSO is not supported for domain user accounts, only
        // work or school (Office 365) or Microsoft Account IDs.
        console.log("SSO failed with error 13003");

        // Show a notification message
        Office.context.mailbox.item.notificationMessages.replaceAsync("action", {
          type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
          message: "Performed action.",
          icon: "Icon.80x80",
          persistent: true
        });

        event.completed();
      } else {
        // Handle error
        console.log("SSO - general failure");

        // Show a notification message
        Office.context.mailbox.item.notificationMessages.replaceAsync("action", {
          type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
          message: "Performed action.",
          icon: "Icon.80x80",
          persistent: true
        });
        console.log(result.error);
        event.completed();
      }
    }
  });
}

function summaryMessageHandler(m) {
  console.log("Message received");
  summaryDialog.close();
  summaryEvent.completed();
}

function summaryEventHandler(e) {
  console.log("summaryEventHandler");
  closeContainer();
  //mailContext.completed({ allowEvent: false});
}

function filingMessageHandler(m) {
  console.log("Filing Message received");
  summaryDialog.close();
  event.completed();
}

function filingEventHandler(e) {
  console.log("Filing EventHandler");
  closeContainer();
  //mailContext.completed({ allowEvent: false});
}

Office.initialize = function(reason) {
  summaryDialog = null;
  filingStatus = null;
  summaryEvent = null;
  console.log("Page reloaded");
  console.log(reason);
};

// Helper function to add a status message to the info bar.
function statusUpdate(icon, text) {
  Office.context.mailbox.item.notificationMessages.replaceAsync("status", {
    type: "informationalMessage",
    icon: icon,
    message: text,
    persistent: false
  });
}

function Log() {}

function getGlobal() {
  return typeof self !== "undefined"
    ? self
    : typeof window !== "undefined"
    ? window
    : typeof global !== "undefined"
    ? global
    : undefined;
}

const g = getGlobal();

// the add-in command functions need to be available in global scope
g.action = action;
g.filingStatus = filingStatus;
